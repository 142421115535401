<template>
  <div class="resetpassword">
   <ResetPassword/>
  </div>
</template>

<script>
import ResetPassword from "../components/ResetPassword.vue";
export default {
    components: { ResetPassword }
}
</script>
<style lang="scss" scoped>

</style>

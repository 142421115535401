<template>
 <div id="resetpassword">
 <main class="main">
      <div class="restpasswordaccount">
        <form @submit.prevent="handleSubmit">
          <img class="logoCloudNet" src="../assets/icon-logo.png" alt="" />
          <h1 class="title-page">Reset Password</h1>
          <p class="breif">Reset Password and start managing your account</p>
          <input
            type="email"
            placeholder="E-mail"
            required=""
            v-model="email"
          />
          <p>LoAlready registered with us?</p>
          <p>
            <router-link class="links" to="/regster">
              <strong>Regster account </strong></router-link
            >
            or
            <router-link class="links" to="/login">
              <strong>Sign In</strong></router-link
            >
          </p>

          <!--<p>By signing up you accept our Privacy Policy, Terms & Licensing Agreement. Protected by reCAPTCHA. Google Privacy Policy & Terms apply.</p>-->
          <button type="submit">Send Email</button>
        </form>
      </div>
    </main>
 </div>
</template>

<script>
 import axios from "axios";
export default {
  name: "ResetPassword",
  data() {
    return {
      email: "",
    };
  },
  methods: {
    async handleSubmit() {
      await axios.post("http://localhost:8000/api/send-reset-email", {
        email: this.email,
      });
      this.$router.push("/newpassword");
    },
  },
};
</script>

<style lang="scss" scoped>
#resetpassword {
 text-align: center;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-family: "Jost", sans-serif;
  background: #171743;
  background: url("../assets/mcCJxI.jpeg") no-repeat center/ cover;

  .header-forms {
    margin-top: 6%;
    margin-bottom: 46px;

    h1 {
      font-size: 35px;
    }
    p {
      font-size: 16px;
    }
  }
  .main {
    width: 363.3px;
    margin: 27px;
    padding-bottom: 20px;
    background: #171743;
    overflow: hidden;
    //background: url("../../../../public/mcCJxI.jpeg") no-repeat center/ cover;
    border-radius: 10px;
    box-shadow: 5px 20px 50px #000;
    .restpasswordaccount {
      position: relative;
      width: 100%;
      height: 100%;
      .logoCloudNet {
        margin-top: 19px;
        width: 80px;
      }
      .title-page {
        font-size: 32px;
        color: #fff;
        margin-top: 9px;
        padding: 5px;
      }
      .breif {
        font-size: 13px;
      }
    }
    label {
      color: #e0dede;
      font-size: 2.3em;
      justify-content: center;
      display: flex;
      margin: 30px;
      font-weight: bold;
      cursor: pointer;
      transition: 0.5s ease-in-out;
    }
    input {
      width: 83%;
      height: 20px;
      background: #e0dede;
      justify-content: center;
      display: flex;
      margin: 27px auto;
      padding: 20px;
      border: 3px solid #2ec1ff;
      outline: none;
      border-radius: 5px;
    }
    button {
      width: 83%;
      height: 48px;
      margin: 10px auto;
      justify-content: center;
      display: block;
      color: #fff;
      background: #8d3de2;
      font-size: 1em;
      font-weight: bold;
      margin-top: 20px;
      outline: none;
      border: none;
      border-radius: 5px;
      transition: 0.2s ease-in;
      cursor: pointer;
    }
    p {
      color: #e0dede;
      margin: 0;
      strong {
        color: #2ec1ff;
        cursor: pointer;
      }
      .links {
        text-decoration: none;
      }
    }
    button:hover {
      background: #6d44b8;
    }
  }
}
</style>